<template>
    <component
        :is="buttonComponent"
        class="button-action"
        type="button"
        :class="buttonClasses"
        :disabled="buttonDisabled"
        v-bind="buttonHref ? { href: buttonHref } : {}"
        :to="buttonRoute"
        @click="buttonClick(buttonClickParams)"
    >
        <slot name="left"></slot>{{ buttonText }}<slot name="right"></slot>
    </component>
</template>

<script>
export default {
    name: 'ButtonAction',
    props: {
        buttonColor: {
            required: false,
            type: String,
            default: 'bg-sky-700 text-white hover:bg-sky-600 focus-visible:outline-sky-600',
        },
        buttonPadding: {
            required: false,
            type: String,
            default: 'px-4 py-2',
        },
        buttonDisabled: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonHref: {
            required: false,
            type: String,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
    },
    computed: {
        buttonComponent () {
            if (this.buttonRoute) return 'router-link';
            if (this.buttonHref) return 'a';
            return 'button';
        },
        buttonClasses () {
            const cls = {};
            cls[this.buttonPadding] = true;
            cls['flex flex-row justify-between'] = true;
            cls['rounded-md'] = true;
            cls['cursor-pointer'] = true;
            cls['focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'] = true;
            cls['text-lg'] = true;
            cls[this.buttonColor] = true;
            cls['disabled:bg-zinc-500'] = !!this.buttonDisabled;
            return cls;
        },
    },
};
</script>
